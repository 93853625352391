import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  base,
  mainnet,
  polygon,
  sepolia
} from 'wagmi/chains';

const walletConnectProjectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

export const config = getDefaultConfig({
  appName: 'Clima',
  projectId: walletConnectProjectId!,
  chains: [
    mainnet,
    polygon,
    base,
    sepolia,
    ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
  ],
  ssr: true,
});
